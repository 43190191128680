@font-face {
    font-family: 'Arimo';
    src: local(Arimo-Regular), url(./fonts/arimo/Arimo-Regular.ttf);
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Arimo';
    src: local(Arimo-Bold), url(./fonts/arimo/Arimo-Bold.ttf);
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Arimo';
    src: local(Arimo-Medium), url(./fonts/arimo/Arimo-Medium.ttf);
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
