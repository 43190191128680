@import url('./_reset.css');
@import url('./_fonts.css');

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: -webkit-fill-available;
    font-size: calc(1em * 0.625);
}

body {
    margin: 0;
    font-family: 'Arimo', sans-serif;
    font-style: normal;
    font-stretch: normal;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.5;
    color: #0f1a42;
}

a {
    color: inherit;
    text-decoration: inherit;
}

a:hover {
    text-decoration: underline;
}

img {
    max-width: 100%;
}

h1 {
    margin-bottom: 2.3rem;
    font-family: Arimo;
    font-size: 2.4rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: #0f1a42;
}

h2 {
    margin-bottom: 2rem;
    font-family: Arimo;
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: #0f1a42;
}

button:focus {
    outline: none;
}

p {
    margin-bottom: 2rem;
    white-space: pre-line;
}
